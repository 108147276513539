<template>
    <ASelect
        mode="multiple"
        v-model:value="valueState"
        placeholder="Pilih Periode Transaksi"
        allow-clear
        show-search
        :not-found-content="state.loading ? undefined : 'Tidak ditemukan'"
        option-filter-prop="label"
        option-label-prop="label"
        @search="onSearch"
        @blur="() => (state.params.q = null)"
        @select="() => (state.params.q = null)"
        v-bind="$attrs"
        :loading="state.loading"
        :show-arrow="true"
        style="width:300px">
        <ASelectOption v-for="d in state.data" :key="d.id" :label="d.name">
            <span v-html="highlight(d.name)"></span>
        </ASelectOption>
        <template v-if="state.loading" #notFoundContent>
            <ASpin size="small" />
        </template>
    </ASelect>
</template>

<script>
import { onMounted, reactive, watch } from 'vue'
import apiClient from '@/services/axios'
import { useVModel } from '@/components/useVModel.js'
import { debounce, pickBy, find } from 'lodash'

export default {
    props: {
        value: {
            type: [Array, Number, Object, String],
            default: null,
        },
        status: {
            type: [String],
            default: () => null,
        },
        default_monthly: {
            type: [Boolean],
            default: () => false,
        },
    },
    emits: ['update:value'],
    setup(props, { emit }) {
        const state = reactive({
            endpoint: `/api/filter/periode-transaksi`,
            loading: false,
            data: [],
            params: {
                q: '',
                status: props.status,
            },
        })

        const fetchData = () => {
            state.loading = true
            apiClient.get(state.endpoint, { params: pickBy(state.params) })
                .then(({ data }) => {
                    state.data = data

                    data.forEach(item => {
                        if (item.status && !props.value) {
                            emit('update:value', item.id)
                        }
                    })

                    if (!props.value) {
                        if (props.default_monthly) {
                            emit('update:value', findIdByMonth(data))
                        }
                    }
                })
                .finally(() => {
                    state.loading = false
                })
        }

        // digunakan untuk memanipulasi default monthly berdasarkan close monthly terakhir
        const findIdByMonth = (data) => {
            const now = new Date()

            // Iterasi mundur dari bulan sekarang
            for (let i = 0; i < 12; i++) {
                const checkDate = new Date(now.getFullYear(), now.getMonth() - i, 1)
                const result = find(data, item => {
                    const itemDate = new Date(item.begin_date)
                    return (
                        itemDate.getFullYear() === checkDate.getFullYear() &&
                        itemDate.getMonth() === checkDate.getMonth()
                    )
                })
                if (result) return result.id
            }

            return null // Jika tidak ada yang cocok
        }

        onMounted(() => {
            fetchData()
        })

        watch(() => props.status, () => {
            state.params.status = props.status
        })

        watch(state.params, debounce(() => {
            fetchData()
        }, 300))

        const onSearch = value => {
            state.params.q = value
        }

        const highlight = value => {
            return value.replace(new RegExp(state.params.q, 'gi'), match => {
                return `<span style="background-color: yellow;">${match}</span>`
            })
        }

        return {
            state,
            valueState: useVModel(props, 'value'),
            highlight,
            onSearch,
        }
    },
}
</script>